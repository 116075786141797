<template>
  <div class="m-tf o-order-step__content">
    <div class="o-order-step-content__row">
      <ASwitchTabs
        class="section-head__tabs"
        :items="dayList"
        :active-tab="day"
        data-transaction-name="Search - Set Active Tab"
        @click="onSetDay"
      />
    </div>

    <MListSelectVertical
      :class="{ 'm-tf__list--pickup': !isDelivery }"
      :items="slots"
    >
      <template #item="{item}">
        <ATimeslotItem
          :item="item"
          :active="checkActive(item)"
          @click.native="onSelect(item)"
        />
      </template>
    </MListSelectVertical>
    <div>
      <ASmoothReflow>
        <div v-if="showError" class="o-order-step-content__error m-tf__error">
          {{ timeDelivery }}
        </div>
        <div v-if="showResetMessage" class="o-order-step-content__error m-tf__error">
          {{ timeSlotsExpireMessage }}
        </div>
      </ASmoothReflow>

      <SfButton
          class="sf-button--primary sf-button--full-width-mobile form__action-button m-tf__action"
          data-transaction-name="Checkout - TimeSlots - Save"
          @click="onSubmit"
      >
        <template v-if="isFilled">
          {{ $t("Save changes") }}
        </template>
        <template v-else>
          {{ $t("Continue") }}
        </template>
      </SfButton>
    </div>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import ATimeslotItem from 'theme/components/atoms/a-timeslot-item.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import MListSelectVertical from 'theme/components/molecules/m-list-select-vertical.vue';
import { format } from '@formkit/tempo'
import ASwitchTabs from 'theme/components/atoms/a-switch-tabs.vue';

export default {
  name: 'MTimeslotForm',
  components: { ASwitchTabs, ASmoothReflow, SfButton, ATimeslotItem, MListSelectVertical },
  props: {
    items: {
      type: Array,
      required: true
    },
    current: {
      type: [Object, null],
      default: () => null
    },
    showResetMessage: {
      type: Boolean,
      required: true
    },
    isFilled: {
      type: Boolean,
      required: true
    },
    isDelivery: {
      type: Boolean,
      required: true
    },
    blockTitle: {
      type: String,
      required: true
    }
  },
  watch: {
    current: {
      immediate: true,
      handler: function (val) {
        if (!val?.date) return;

        const day = this.getFormattedKey(val.date)

        if (day && !this.groups?.days?.includes(day)) {
          this.day = this.groups?.days?.[0]

          return
        }

        if (day === this.day) return

        this.day = day
      }
    }
  },
  directives: {},
  data () {
    return {
      showError: false,
      day: null
    }
  },
  computed: {
    slots () {
      if (!this.day) return []
      return this.groups.groups[this.day]
    },
    groups () {
      const groups = this.items.reduce((a, c) => {
        const key = this.getFormattedKey(c.date)

        if (a[key]) {
          a[key].push(c)
          return a
        }

        a[key] = [c]

        return a
      }, {})

      const days = Object.keys(groups).sort().slice(0, 3)

      return {
        groups,
        days
      }
    },
    dayList () {
      return this.groups.days.map((d) => {
        const label = this.mapDate(d)

        return {
          value: d,
          label: label?.charAt(0)?.toUpperCase() + label?.slice(1)
        }
      })
    },
    timeSlotsExpireMessage () {
      const type = this.$t(this.isDelivery ? 'delivery for' : 'pickup for')
      return this.$t('Your specified {type} time is busy', { type })
    },
    timeDelivery () {
      return this.$t('Please select a {type}', { type: (this.blockTitle || '').toLowerCase() })
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    getFormattedKey (date) {
      try {
        const [day, month, year] = date.split('-')
        const inputDate = new Date(`${year}-${month}-${day}T00:00:00+02:00`)
        return format({
          date: inputDate,
          format: 'YYYY-MM-DD',
          tz: 'Europe/Kiev'
        })
      } catch (e) {
        console.error('Key formatting error:', e, date)
        return date
      }
    },
    checkActive (item) {
      return item?.id === this.current?.id
    },
    mapLabel (now) {
      try {
        return now.tz('Europe/Kyiv')
      } catch (e) {
        return now
      }
    },
    mapDate (dateVal) {
      try {
        const [year, month, day] = dateVal.split('-')
        const kievDate = new Date(Date.UTC(year, month - 1, day))
        kievDate.setUTCHours(2)

        const now = new Date()
        const kievNow = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Kiev' }))
        const today = format({
          date: kievNow,
          format: 'YYYY-MM-DD'
        })

        if (dateVal === today) return this.$t('Today')

        const tomorrow = format({
          date: new Date(kievNow.getTime() + 86400000),
          format: 'YYYY-MM-DD'
        })

        if (dateVal === tomorrow) return this.$t('Tomorrow')

        const result = format({
          date: kievDate,
          format: 'DD MMM, ddd',
          locale: this.$i18n?.locale || 'uk'
        })

        const [date, dayOfWeek] = result.split(',')
        const formattedDayOfWeek = dayOfWeek.trim().charAt(0).toUpperCase() + dayOfWeek.trim().slice(1)
        return `${date}, ${formattedDayOfWeek}`
      } catch (e) {
        console.error('Date parsing error:', e, dateVal)
        return dateVal
      }
    },
    onSelect (item) {
      this.showError = false

      this.$emit('set-timeslot', item)
    },
    onLoad () {
      this.day = this.day || this.dayList?.[0]?.value
    },
    onSetDay (e) {
      this.day = e?.value
    },
    onSubmit () {
      if (!this.current) {
        this.showError = true
        return
      }

      this.$emit('submit')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-order-step";

.m-tf {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);

  &__list {
    &--pickup {
      --mls-v-col-mobile: 3;
      --mls-v-mobile-max-height: 232px;
      --mls-v-desktop-max-height: 232px;

      @media (max-width: 354px) {
        --mls-v-col-mobile: 2;
      }

    }
  }

  @media (max-width: $mobile-max) {
    max-width: initial;
  }

  .m-tf__action {
    margin-top: var(--spacer-8)
  }

  .m-tf__error {
    padding-bottom: var(--spacer-16)
  }

  @include for-desktop {
    padding-right: var(--spacer-10);
  }
}
</style>
