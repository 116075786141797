<template>
  <ASmoothReflow class="m-ab">
    <div
      v-if="payWithBonuses.available && isLoggedIn"
      class="m-ab__block"
      :class="{'m-ab__block--inactive': !isAvailable}"
    >
      <template>
        <span>
          {{ $t('Write off available bonuses') }}
          <b>
             {{ payWithBonuses.available }}
          </b>
          {{ $t('Bonuses plural') }}
        </span>

        <ACheckboxToggle
          :value="available"
          @input="handleFilterChange"
        />
      </template>
    </div>

    <MInfoBlock
      v-if="!isLoggedIn"
      class="m-ab__notification"
      :message="$t('Log in to use bonuses')"
    />

    <MInfoBlock
      v-else-if="!isAvailable && showPaymentMessage"
      class="m-ab__notification"
      :message="$t('Bonuses notice')"
    />

    <MInfoBlock
      v-else-if="!payWithBonuses.available"
      class="m-ab__notification"
      :message="$t('Your account, there are no bonuses available for debiting')"
    />

    <MInfoBlock
      v-else-if="available && payWithBonuses.bonusesChanged"
      class="m-ab__notification"
      :message="$t('The number of bonuses available for redemption has been updated')"
    />
  </ASmoothReflow>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle.vue';
import { useStoreHelpers } from '../../../../composables/useStore';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import MInfoBlock from 'theme/components/molecules/m-info-block.vue';
import { PaymentBonusesAvailable } from 'theme/store/payment';

export default defineComponent({
  name: 'MAvailableBonuses',
  components: { MInfoBlock, ASmoothReflow, ACheckboxToggle },
  props: {
    payment: {
      type: Object,
      required: true
    },
    showPaymentMessage: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { useState, useAction, useGetter } = useStoreHelpers()

    const payWithBonuses = useState('checkout', 'payWithBonuses')
    const makeActive = useAction('checkout', 'payWithBonusesActive')
    const isLoggedIn = useGetter('user', 'isLoggedIn')

    const isAvailable = computed(() => (
      !props.showPaymentMessage
        ? true
        : PaymentBonusesAvailable.includes(props?.payment?.paymentMethod)
    ))

    const available = computed(() => {
      if (!props.showPaymentMessage) {
        return payWithBonuses.value?.active
      }

      return payWithBonuses.value?.active && isAvailable.value
    })

    const isFilterActive = ref(null)

    const handleFilterChange = () => {
      makeActive(!payWithBonuses?.value?.active)
    }

    return {
      available,
      payWithBonuses,
      isAvailable,
      isLoggedIn,
      isFilterActive,
      handleFilterChange
    }
  }
});
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';

.m-ab {
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacer-10);
    font-size: var(--font-size-14);
    margin-bottom: var(--spacer-16);
    transition: background 200ms;
    background: rgba(235, 103, 71, 0.1);

    &--inactive {
      opacity: 0.6;
      background: var(--light-gray);

      ::v-deep {
        .a-checkbox-toggle {
          pointer-events: none;
        }
      }
    }
  }

  &__notification {
    margin-bottom: var(--spacer-16);
    background: var(--yellow-corn-silk);
  }

  &__notification-inside {
    background: transparent;
    padding: 0;
  }
}
</style>
